<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters>
        <v-col cols="12" md="10">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for people"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-end">
          <div class="mt-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <bullet :title="'List Account Officer'" />
        </v-col>
        <v-col cols="12" md="8">
          <v-row no-gutters align="center" justify="end">
            <div class="col_tollbar">
              <v-btn
                depressed
                class="text-capitalize btn_tollbar rounded-lg bg_color1"
                @click="createData"
                dark
              >
                <v-icon> mdi-plus</v-icon> Tambah
              </v-btn>
            </div>
            <div class="col_tollbar">
              <v-btn
                depressed
                dark
                color="#CFE1E1"
                class="text-capitalize btn_tollbar rounded-lg"
                @click="
                  changeSort(
                    sort == '-created_at' ? 'created_at' : '-created_at'
                  )
                "
              >
                <v-icon
                  color="#106967"
                  v-if="sort == 'created_at'"
                  class="mr-2"
                  dark
                >
                  mdi-sort-descending
                </v-icon>
                <v-icon
                  color="#106967"
                  v-if="sort == '-created_at'"
                  class="mr-2"
                  dark
                >
                  mdi-sort-ascending
                </v-icon>
                <h4 style="color: #106967">
                  {{ sort == "-created_at" ? "Terbaru" : "Terlama" }}
                </h4>
              </v-btn>
            </div>
            <!-- <div class="col_tollbar">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    dark
                    color="#CFE1E1"
                    class="text-capitalize btn_tollbar rounded-lg"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#106967"> mdi-filter </v-icon>
                    <h4 style="color: #106967">Filter</h4>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-btn
                      class="text-capitalize"
                      text
                      @click="changeFilter('marketing')"
                    >
                      Marketing
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      class="text-capitalize"
                      text
                      @click="changeFilter('assessment')"
                    >
                      Assessment
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div> -->
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-card flat class="card_on_view">
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
        v-if="loading"
      ></v-skeleton-loader>
      <div v-if="!loading && dataSet">
        <v-data-table
          :headers="headers"
          :items="dataSet"
          hide-default-footer
          :page.sync="page"
          :items-per-page="limit"
          class="elevation-0"
          :single-select="false"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <!-- DI COMMENT DULU KARENA CUMA ADA UPDATE NAMA -->
              <!-- bisa aja edit email & password -->
              <!-- tergantung kebijakanya gimana, apakah superadmin bisa update email & password-nya account officer? -->
              <!-- <v-btn
                small
                icon
                depressed
                class="mr-2"
                @click="updateData(item)"
              >
                <v-icon small> mdi-pencil </v-icon>
              </v-btn> -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    icon
                    depressed
                    color="red"
                    @click="deleteData(item)"
                  >
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Hapus Account Officer</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pa-3">
          <v-pagination
            color="#052633"
            v-model="page"
            @input="fetchData"
            :length="last_page"
          ></v-pagination>
        </div>
      </div>
    </v-card>
    <div class="pa-2">
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  name: "listOfficer",
  components: {
    bullet,
    NavbarProfile,
  },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      menuLimit: false,
      loading: false,
      dataSet: null,
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      page: 1,
      limit: 10,
      find: "",
      selected: null,
      last_page: null,
      total_page: null,
      sort: "-created_at",
      filter: "",
      headers: [
        { text: "Nama", value: "profile.nama", width: "30%" },
        { text: "No. Telepon", value: "profile.telepon" },
        { text: "NIP", value: "profile.nip", width: "15%" },
        { text: "Departemen", value: "profile.departemen", sortable: false },
        {
          text: "Posisi",
          value: "profile.jabatan",
          width: "20%",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List AO",
          disabled: true,
          href: "#",
        },
      ],
      body: {
        username: "",
        nama: "",
        telepon: "",
        nip: "",
        no_sipp: "",
        departemen: "",
        jabatan: "",
        atasan: "",
        jabatan_atasan: "",
        email: "",
        password: "",
        klien_id: 1,
      },
      idx: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // tambah akun oficer dan buka popup
    createData() {
      this.inventory = {
        body: this.body,
        path: `sysadmin/user/ACCOUNTOFFICER`,
        type: "c",
      };
      this.d_type = "c_ao";
      this.d_title = "Tambah Account Officer";
      this.d_create = true;
      this.line = true;
    },

    // update akun oficer
    updateData(item) {
      this.inventory = {
        body: {
          id: item.id,
          nama: item.profile.nama,
          email: item.email,
          password: item.password,
        },
        path: `sysadmin/user/akun/update`,
      };
      this.d_type = "c_ao";
      this.d_title = "Edit Account Officer";
      this.d_create = true;
      this.line = true;
    },

    // delete akun oficer
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/user/akun/delete`,
        deleteType: "Officer",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Account Officer";
      this.d_small = true;
      this.line = true;
    },

    // ubah sorting
    changeSort(item) {
      this.sort = item;
      this.fetchData();
    },

    // ubah filter
    changeFilter(item) {
      this.filter = item;
      this.fetchData();
    },

    // get list acount officer
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/user/akun/list?page[size]=${this.limit}&page[number]=${this.page}&sort=${this.sort}&include=profile`,
      };
      this.$store
        .dispatch("officer/getData", data)
        .then((data) => {
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },

    // ubah limit list per page
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
</style>
